body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  height: fit-content;
}

:root {
  --Hover: #005CB5;
  --orange-1: #FC812B;
  --orange-hover: #DC6F23;
  --orange-2: #FEC9A4;
  --Light-Blue: #75C2F6;
  --black-light: rgba(0, 0, 0, 0.50);
  --black-default: #000;
  --black-lighter: rgba(0, 0, 0, 0.25);
  --white-default: #FFF;
  --corners_btn: 4px;
  --gray-light: #C2C9D1;
  --gray-lighter: linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #C2C9D1;
  --error-default: #F03738;
  --error-hover: #CF1322;
  --red-error-1: #FF7875;
  --red-error-2: #F5222D;
  --grey-1: #FDFDFD;
  --grey-2: #FAFAFA;
  --grey-3: #f5f5f5;
  --grey-4: #d9d9d9;
  --grey-5: #8c8c8c;
  --grey-6: #595959;
  --grey-7: #262626;
  --grey-8: #141414;
  --gray-100: #F2F4F7;
  --teal-0: #CEFAF6;
  --teal-1: #A8E5DF;
  --teal-2: #81D0C9;
  --teal-3: #5BBCB2;
  --teal-4: #34A79C;
  --teal-5: #0E9285;
  --teal-6: #0B756A;
  --teal-7: #085850;
  --teal-8: #063A35;
  --teal-9: #031D1B;
  --teal-10: #070707;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

a {
  text-decoration: none;
  color: #FFF;
}

ul, li, h2, p, body, h1, h3, ol, a {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.container {
  max-width: 1110px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}

@media (max-width: 1142px) {
  .container {
    padding: 0 16px;
  }
}

.errorText {
  color: var(--error-default);
}

.textBold28 {
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
}

.textBold24 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.textBase18 {
  font-size: 18px;
  line-height: 28px;
}

.textBase16 {
  font-size: 16px;
  line-height: 24px;
}

.textBase14 {
  font-size: 14px;
  line-height: 22px;
}

.textBase12 {
  font-size: 12px;
  line-height: 18px;
}

.textBase10 {
  font-size: 10px;
  line-height: 16px;
}

.textSemiBold32 {
  font-size: 22px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .textSemiBold32 {
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .textSemiBold32 {
    font-size: 32px;
  }
}

.textSemiBold22 {
  font-size: 22px;
  line-height: 34px;
  font-weight: 600;
}

.textSemiBold18 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
}

.textSemiBold16 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.textSemiBold14 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.textSemiBold12 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}

.textSemiBold {
  font-weight: 600;
}

.textBold {
  font-weight: 700;
}

.textExtraBold {
  font-weight: 800;
}


@media (max-width: 1024px) {

}

@media (max-width: 600px) {
  .textBase18 {
    font-size: 16px;
    line-height: 24px;
  }

  .textBase16 {
    font-size: 14px;
    line-height: 22px;
  }
}

.animationUnderline {
  position: relative;
}

.animationUnderline::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #000;

  transition: 0.3s ease all;
}

.colorBlack {
  color: #000;
}

.animationUnderline:hover::after {
  width: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;

  padding: 14px 30px;

  color: #FFF;

  border: 1px solid #FC812B;
  border-radius: var(--corners_btn, 4px);
  background: var(--orange-1, #FC812B);
  box-shadow: 0px 2px 3px 1px rgba(252, 129, 43, 0.30), 0px 0px 2px 0px rgba(252, 129, 43, 0.40);
  box-sizing: border-box;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  transition: 0.3s ease all;
}

.button:hover {
  border: 1px solid #DC6F23;
  background: var(--orange-hover, #DC6F23);
  box-shadow: 0px 3px 6px 2px rgba(220, 111, 35, 0.40), 0px 1px 2px 0px rgba(220, 111, 35, 0.50);
}

.button:focus {
  outline: 2px solid var(--orange-2, #FEC9A4);

  background: var(--orange-1, #FC812B);
  box-shadow: 0px 1px 3px 1px rgba(252, 129, 43, 0.15), 0px 1px 2px 0px rgba(252, 129, 43, 0.30);
}

.buttonWhite {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;

  padding: 14px 30px;

  color: var(--orange-1, #FC812B);

  border: 1px solid var(--orange-1, #FC812B);
  background: var(--grey-1, #FDFDFD);
  box-shadow: 0px 2px 3px 1px rgba(252, 129, 43, 0.30), 0px 0px 2px 0px rgba(252, 129, 43, 0.40);

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  box-sizing: border-box;

  transition: 0.3s ease all;
}

.buttonWhite:hover {
  color: var(--orange-hover, #DC6F23);

  border: 1px solid var(--orange-hover, #DC6F23);
  background: var(--grey-1, #FDFDFD);
  box-shadow: 0px 3px 6px 2px rgba(220, 111, 35, 0.40), 0px 1px 2px 0px rgba(220, 111, 35, 0.50);
}

.buttonWhite:focus {
  color: var(--orange-1, #FC812B);

  outline: 2px solid var(--orange-2, #FEC9A4);
}

